import React, { useState } from 'react';
import axios from 'axios';

const GenerateScript = ({ token }) => {
    const [deviceName, setDeviceName] = useState('');
    const [password, setPassword] = useState('');
    const [script, setScript] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post('/api/auth/devices/script', { deviceName, password }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        setScript(response.data);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="text" value={deviceName} onChange={(e) => setDeviceName(e.target.value)} placeholder="Device Name" />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                <button type="submit">Generate Script</button>
            </form>
            {script && (
                <div>
                    <h3>Connection Script:</h3>
                    <pre>{script}</pre>
                </div>
            )}
        </div>
    );
};

export default GenerateScript;
