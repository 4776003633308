import React, { useState } from 'react';
import axios from 'axios';

const AddDevice = ({ token }) => {
    const [deviceName, setDeviceName] = useState('');
    const [connectionType, setConnectionType] = useState('L2TP');
    const [serverIP, setServerIP] = useState('');
    const [port, setPort] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post('/api/auth/devices', { deviceName, connectionType, serverIP, port }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        alert('Device added');
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" value={deviceName} onChange={(e) => setDeviceName(e.target.value)} placeholder="Device Name" />
            <select value={connectionType} onChange={(e) => setConnectionType(e.target.value)}>
                <option value="L2TP">L2TP</option>
                <option value="Winbox">Winbox</option>
                <option value="API-SSL">API-SSL</option>
                <option value="API">API</option>
                <option value="Telnet">Telnet</option>
                <option value="WWW">WWW</option>
            </select>
            <input type="text" value={serverIP} onChange={(e) => setServerIP(e.target.value)} placeholder="Server IP" />
            <input type="number" value={port} onChange={(e) => setPort(e.target.value)} placeholder="Port" />
            <button type="submit">Add Device</button>
        </form>
    );
};

export default AddDevice;
