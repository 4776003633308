import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Use Routes instead of Switch
import Register from './components/Register';
import Login from './components/Login';
import AddDevice from './components/AddDevice';
import GenerateScript from './components/GenerateScript';

const App = () => {
    const [token, setToken] = useState('');

    return (
        <Router>
            <Routes> {/* Replace Switch with Routes */}
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login setToken={setToken} />} />
                <Route path="/add-device" element={<AddDevice token={token} />} />
                <Route path="/generate-script" element={<GenerateScript token={token} />} />
            </Routes>
        </Router>
    );
};

export default App;
